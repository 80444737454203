import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

/*---CVT---*/
import { Button } from '../../components/cvt/basic/button';
import { CvtColorSchema } from '../../global';
import { BlockTop } from '../../components/cvt/layout/block-top';
import { BlockStandard } from '../../components/cvt/layout/block-standard';

/*---Components---*/
import BlogPost from './BlogPost';

/*---Global---*/
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

/*---Images---*/
import ImgBlog from '../../images/Pics/pic_blog_stage.svg';

export const BlogPostPageTemplate = ({
  content,
  author,
  title,
}: {
  content: any;
  author: string;
  title: string;
  date: any;
}) => {
  return (
    <section className="section">
      <div
        style={{
          backgroundColor: CvtColorSchema.green,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BlockTop
          headline="Blog"
          subheadline="dranbleiben!"
          diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
          bgImage={ImgBlog}
          zIndex={8}
          padding={PaddingObj.PaddingStarter}
          color={CvtColorSchema.fontDarkGray}
        />
      </div>

      <Link to={SYSTEM_STRINGS.LINKS.INTERN.BLOG.MAIN}>
        <Button
          bgColor={CvtColorSchema.yellow}
          color={CvtColorSchema.fontDarkGray}
          text="Alle Beiträge"
          hoverColor={CvtColorSchema.green}
          name={'Standardbutton'}
          type={'button'}
          disabled={false}
        />
      </Link>

      <img alt="Bild des Autors" />

      <BlockStandard
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        padding={PaddingObj.PaddingSinglePostBlog}
        color={CvtColorSchema.fontDarkGray}
        bgColor={CvtColorSchema.white}
        zIndex={7}
      >
        <span>{author}</span>
        <h1>{title}</h1>
        <div>{content}</div>
      </BlockStandard>
    </section>
  );
};

const BlogPostPage = ({ data }: { data: any }) => {
  const post = data.markdownRemark.frontmatter;
  const content = data.markdownRemark.html;

  const featuredImage = post.featuredImage
    ? post.featuredImage.childImageSharp.resize
    : null;

  return (
    <BlogPost
      author={post.author}
      title={post.title}
      seoTitle={post.pageTitle}
      seoDescription={post.pageDescription}
      seoKeywords={post.seoKeywords}
      date={post.date}
      content={content}
      categories={post.category}
      featuredImage={featuredImage}
      tags={post.tags}
    />
  );
};

export const postQuery = graphql`
  query blogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        author
        pageTitle
        pageDescription
        seoKeywords
        date
        category
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
        tags
      }
    }
  }
`;

export default BlogPostPage;
